.auth-page {
  .auth-left-wrapper {
    width: 100%;
    height: 100%;
    background-color: #1026FB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        width: 80%;
        height: auto;
    }
  }
}
