// Write your custom css/scss here
.pagination {
    margin-bottom: 0;
}

button.btn-search {
    padding: 0.2rem 0.8rem;
}

button.tags {
    padding: 0.3rem 1rem;
}
button.tags::after {
    margin-left: 0 !important;
}

.related_product {
    margin-left: 10px;
    cursor: pointer;
}

.related_product-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

body .table-orders td {
    border-top: 1px solid #b7b7b7 !important;
}

body .table-orders td.border-top-0 {
    border-top: 0px!important;
}

/* ICONS SIZE */
.size-16 {
    height: 16px;
    width: 16px;
}

// Order states
.btn-state-action {
    margin-right: -3px;
}

.btn.disabled {
    opacity: 0.3;
    cursor: default;
}

button.state-retirar:hover svg,
.btn.disabled svg {
    fill: #FFF
}

.state {
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #F1F1F1;
    padding: 0 10px;

    &-pendiente_de_pago {
        background: #d5d5d5;
        border-color: #d5d5d5;
        color: #7d7d7d;
    }

    &-en_proceso {
        background-color: #49afcd;
        border-color: #49afcd;
        color: white;
    }

    &-cliente {
        background-color: dodgerblue;
        border-color: dodgerblue;
        color: white;
    }

    &-impreso {
        /*background-color: darkviolet;
        border-color: darkviolet;*/
        background-color: #c0f;
        border-color: #c0f;
        color: white;
    }

    &-reimpreso {
        background-color: #a502ce;
        border-color: #a502ce;
        color: white;
    }

    &-en_mano {
        background-color: grey;
        border-color: grey;
        color: white;
    }

    &-preparado {
        background-color: #dad700;
        border-color: #dad700;
        color: black;
    }
    
    &-admin, &-para-enviar-btn {
        /*background-color: #ffc107;
        border-color: #ffc107;
        color: black;*/
        background-color: #faa732;
        border-color: #faa732;
        color: #FFF;
    }

    &-empaquetado {
        background-color: #faa732;
        border-color: #faa732;
        color: #FFF;
    }

    &-enviado, &-disponible {
        /*background-color: #10b759;
        border-color: #10b759;*/
        background-color: #5bb75b;
        border-color: #5bb75b;
        color: white;
    }

    &-entregado {
        /*background-color: #10b759;
        border-color: #10b759;*/
        background-color: #faa732;
        border-color: #faa732;
        color: white;
    }

    &-indicar_casilla {
        background-color: yellow;
        border-color: yellow;
        color: black;
    }

    &-cancelado {
        background-color: black;
        border-color: black;
        color: white;
    }

    &-cancelado-but {
        background-color: #da4f49;
        border-color: #da4f49;
    }

    &-retirar {
        /*background-color: #64E8E4;
        border-color: #64E8E4;*/
        background-color: #dad700;
        border-color: #dad700;
        color: black;
    }

    &-encuadernar {
        background-color: red;
        border-color: red;
        color: white;
    }

    &-encuadernado-pers {
        background-color: darkorange;
        border-color: darkorange;
        color: white;
    }

    &-manual {
        background-color: darkseagreen;
        border-color: darkseagreen;
        color: white;
    }

    //Row backgrounds
    &-bg-proceso {
        background-color: white !important;

        input[type="checkbox"] {
            accent-color: dodgerblue;
        }
    }

    &-bg-cancelado, &-bg-mano {
        background-color: #eeeeee !important;
        border-color: #eeeeee !important;

        input[type="checkbox"] {
            accent-color: black;
        }
    }

    &-bg-impreso {
        background-color: #e0cfe8 !important;
        border-color: #e0cfe8 !important;

        td {
            border-top: unset !important;
        }

        input[type="checkbox"] {
            accent-color: darkviolet;
        }
    }

    &-bg-retirar {
        background-color: #d9fefc !important;
        border-color: #ebd3d3 !important;

        td {
            border-top: unset !important;
        }

        input[type="checkbox"] {
            accent-color: #98e5e1;
        }
    }

    &-bg-casilla {
        background-color: #ffffe5 !important;
        border-color: #e5e5ce !important;

        td {
            border-top: unset !important;
        }

        input[type="checkbox"] {
            accent-color: yellow;
        }
    }

    &-bg-preparado, &-bg-empaquetado {
        background-color: #fff3cd !important;
        border-color: #ffeeba !important;

        td {
            border-top: unset !important;
        }

        input[type="checkbox"] {
            accent-color: #ffc107;
        }
    }

    &-bg-enviado , &-bg-disponible , &-bg-entregado {
        background-color: #cff1de !important;
        border-color: #bcebd1 !important;

        td {
            border-top: unset !important;
        }

        input[type="checkbox"] {
            accent-color: #10b759;
        }
    }
}

//Order comment button
.comment {

    .dropdown-menu {
        background-color: #1026FB;
        color: white;
        font-weight: bold;
        border: none;
    }

    button {
        background-color: transparent;
        border: none;

        svg {
            stroke: #0e4cfd;
        }
    }
}

//Order shipping info
.row-shipping {

    .dropdown-menu {
        background-color: #1026FB;
        color: white;
        font-weight: bold;
        border: none;
    }

    button {
        background-color: transparent;
        border: none;

        svg {
            // fill: #0e4cfd;
            stroke: #0e4cfd;
        }
    }

}

//Member administrator
.bg-member-active {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

//Permission center tables
.permissions-table tr > th:first-child, .permissions-table tr > td:first-child {
    position: sticky;
    min-width: 290px;
    left: 0;
    z-index: 1;
}
.permissions-table tr > th:nth-child(2), .permissions-table tr > td:nth-child(2) {
    position: sticky;
    left: 290px;
    background: $white;
    z-index: 0;
}
.permissions-table tr:nth-child(odd) td, .permissions-table tr:nth-child(even) td {
    background: $white;
}

//Color Badges
.badge.badge-violet {
    background-color: darkviolet;
    color: white;
}

.badge.badge-orangered {
    background-color: orangered;
    color: white;
}

//Session messages
.session-fixed {
    top: 60px !important;
    width: calc(100% - 70px);
    z-index: 1030;
    display: block;
    margin-left: 70px;
    transition: width 0.1s ease;
}
.session-fixed-folded {
    width: calc(100% - 270px) !important;
    margin-left: 270px;
}
